<div style="display:flex;flex-flow:row" [formGroup]="formGroup">
  <div style="margin-right:10px">
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num1 matInput type="text" maxLength="1" (keyup)="setFocus($event, 1, num2)" formControlName="num1">
    </mat-form-field>
  </div>
  <div style="margin-right:10px">
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num2 matInput type="text" maxLength="1" (keyup)="setFocus($event, 2, num3)" formControlName="num2">
    </mat-form-field>
  </div>
  <div style="margin-right:10px">
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num3 matInput type="text" maxLength="1" (keyup)="setFocus($event, 3, num4)" formControlName="num3">
    </mat-form-field>
  </div>
  <div style="margin-right:10px">
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num4 matInput type="text" maxLength="1" (keyup)="setFocus($event, 4, num5)" formControlName="num4">
    </mat-form-field>
  </div>
  <div style="margin-right:10px">
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num5 matInput type="text" maxLength="1" (keyup)="setFocus($event, 5, num6)" formControlName="num5">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" style="width:40px">
      <label></label>
      <input #num6 matInput type="text" maxLength="1" (keyup)="setFocus($event, 6, null)" formControlName="num6">
    </mat-form-field>
  </div>
</div>
