<div class="alert alert-primary" role="alert">
  <div style="display:flex;flex-flow:row">
    <div>
      Welcome to the Firewall Settings. These refer to access to the system and do not replace the infrastructure firewall settings.
    </div>
  </div>
</div>
<div [formGroup]="formGroup">
  <div>
    <h3>Countries</h3>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="countrySelectionType">
      <mat-button-toggle value="bl">Blacklist Countries</mat-button-toggle>
      <mat-button-toggle value="wl">Whitelist Countries</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="blCountry">
    <strong>Blacklist Countries</strong>
    <p>Blacklist the countries below and allow all others.</p>
  </div>
  <div *ngIf="!blCountry">
    <strong>Whitelist Countries</strong>
    <p>Whitelist the countries below and block all others.</p>
  </div>
  <div>
    <mat-form-field class="example-chip-list" style="width:100%" appearance="outline">
      <mat-label>Countries</mat-label>
      <mat-chip-grid #chipList aria-label="Countries">
        <mat-chip-row *ngFor="let country of selectedCountries"
                      (removed)="removeCountry(country.value)">
          <app-flag [country]="country.countryCode" size="XS"></app-flag>&nbsp;{{country.value}} ({{country.countryCode}})
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input placeholder="Countries..."
               #countryInput
               formControlName="countries"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addCountry($event)">
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.value">
          <app-flag [country]="country.countryCode" size="XS"></app-flag>&nbsp;{{country.value}} ({{country.countryCode}})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div>
    &nbsp;
  </div>
  <div>
    <h3>IP Addresses</h3>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="ipAddressSelectionType">
      <mat-button-toggle value="bl">Blacklist IP Addreses</mat-button-toggle>
      <mat-button-toggle value="wl">Whitelist IP Addresses</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="blIpAddress">
    <strong>Blacklist IP Addresses</strong>
    <p>Blacklist the IP Addresses below and allow all others.</p>
  </div>
  <div *ngIf="!blIpAddress">
    <strong>Whitelist IP Addresses</strong>
    <p>Whitelist the IP Addresses below and block all others.</p>
  </div>
  <div>
    <mat-form-field appearance="outline" class="user-details-fields-width">
      <mat-label>IP Addresses</mat-label>
      <input matInput placeholder="IP Addresses" formControlName="ipAddresses">
    </mat-form-field>
  </div>
  <div>
    &nbsp;
  </div>
  <div>
    <h3>Usernames</h3>
  </div>
  <div>
    <strong>Whitelisted Email Addresses</strong>
    <p>The email addresses below can be used to register and sign into the platform over and above the Country and IP address whitelist/blacklisting.</p>
  </div>
  <div>
    <mat-form-field appearance="outline" style="width:100%">
      <mat-label>Username Whitelist</mat-label>
      <textarea matInput formControlName="whitelistedUsernames" class="scroll fullWidth"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="20"></textarea>
    </mat-form-field>
  </div>
  <div>
    <strong>Blacklisted Usernames</strong>
    <p>The usernames below cannot be used to register and sign into the platform over and above the Country and IP address whitelist/blacklisting.</p>
  </div>
  <div>
    <mat-form-field appearance="outline" style="width:100%">
      <mat-label>Username Whitelist</mat-label>
      <textarea matInput formControlName="blacklistedUsernames" class="scroll fullWidth"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="20"></textarea>
    </mat-form-field>
  </div>
  <div class="user-details-updtProfile-btn-div">
    <button mat-raised-button color="primary" (click)="update()">Update</button>&nbsp;
  </div>
</div>
