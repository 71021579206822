import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, TFAModel } from '../../authentication.Service';
import { DeviceModel, UserProfile, UserService } from '../../user.Service';
import { TfentryComponent } from './tfentry/tfentry.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-two-factor-autentication',
  templateUrl: './two-factor-autentication.component.html',
  styleUrls: ['./two-factor-autentication.component.css']
})
export class TwoFactorAutenticationComponent implements OnInit {

  firstFormGroup: FormGroup = new FormGroup({});
  choiceNotMade = true;
  hasSent = false;
  hasSmsSent = false;
  emailSetup = false;
  smsSetup = false;
  smsEnabled = false;
  code: string;
  devices: DeviceModel[] = [];

  @ViewChild('tfentrysms') tfentrysms: TfentryComponent;
  @ViewChild('tfentryemail') tfentryemail: TfentryComponent;

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private snackbar: MatSnackBar
    ) { }

  ngOnInit(): void {
    this.firstFormGroup = new FormGroup({
      MobileNumber: new FormControl("", [Validators.required]),
      AccessCode: new FormControl(),
      SmsAccessCode: new FormControl()
    });

    if (this._user) {
      this.firstFormGroup.controls.MobileNumber.setValue(this._user.MobileNumber);
    }
  }

  @Output() cancel = new EventEmitter();

  @Input()
  get setup(): boolean {
    return this._setup;
  }
  set setup(value: boolean) {
    this._setup = value;
  }
  private _setup: boolean = false;

  @Input()
  get user(): UserProfile {
    return this._user;
  }
  set user(value: UserProfile) {
    this._user = value;
    if (this._user) {
      if (this.firstFormGroup.controls.MobileNumber) {
        this.firstFormGroup.controls.MobileNumber.setValue(this._user.MobileNumber);
      }
      this.hasSent = this.emailSetup = (this._user.TwoFA & 1) == 1;
      this.hasSmsSent = this.smsSetup = (this._user.TwoFA & 2) == 2;

      for (let i = 0; i < this.user.Member.Devices.length && i < 10; i++) {
        let a = this.user.Member.Devices[i];
        let inserted = false;
        /*for (let j = 0; j < this.devices.length; j++) {
          let b = this.devices[j];
          if (a.LastUsed < b.LastUsed) {
            this.devices.splice(j, 0, a);
            inserted = true;
            break;
          }
        }*/

        if (!inserted) {
          this.devices.push(a);
        }
      }
    }
  }
  private _user: UserProfile;

  cancelled() {
    this.cancel.emit(this);
  }

  send2FAEmail(event) {
    event.stopPropagation();
    this.tfentryemail?.setFocusToControl();
    this.authenticationService.sendEmail2FA().subscribe({
      next: (result) => {
        this.hasSent = true;
        this.code = result;
        this.openSnackbar("The Access Code has been sent by Email", "");
      },
      error: (error) => {
        this.openSnackbar("There was a problem sending the Access Code by Email", "Cancel");
      }
    });
  }

  confirm2FAEmail() {
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          let model = new TFAModel();
          model.IpAddress = result.ip;
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.confirm2FAEmaila(model);
        },
        error: (error) => {
          let model = new TFAModel();
          model.IpAddress = "Unknown";
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.confirm2FAEmaila(model);
        }
      });
  }

  confirm2FAEmaila(model: TFAModel) {
    this.authenticationService.check2FA(model).subscribe({
      next: (result) => {
        this.hasSent = true;
        this.emailSetup = true;
        this._user.TwoFA |= 1;
        this.updateUserProfile();
      },
      error: (error) => {
        this.openSnackbar("There was a problem with the Access Code", "Cancel");
      }
    });
  }

  send2FASms() {
    this.tfentrysms?.setFocusToControl();
    this.user.MobileNumber = this.firstFormGroup.controls.MobileNumber.value;
    this.userService.setUser(this._user).subscribe(result => {
      this.authenticationService.sendSms2FA().subscribe(result => {
        this.hasSmsSent = true;
        this.openSnackbar("The Access Code has been sent by SMS", "");
      },
        error => {
          this.openSnackbar("There was a problem sending the Access Code by SMS", "Cancel");
        });
    });
  }

  confirm2FASms() {
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          let model = new TFAModel();
          model.IpAddress = result.ip;
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.confirm2FASmsa(model);
        },
        error: (error) => {
          let model = new TFAModel();
          model.IpAddress = "Unknown";
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.confirm2FASmsa(model);
        }
      });
  }

  confirm2FASmsa(model: TFAModel) {
    this.authenticationService.check2FA(model).subscribe({
      next: (result) => {
        this.hasSmsSent = true;
        this.smsSetup = true;
        this._user.TwoFA |= 2;
        this.updateUserProfile();
      },
      error: (error) => {
        this.openSnackbar("There was a problem sending the Access Code by SMS", "Cancel");
      }
    });
  }

  disableSms2FA() {
    this.smsSetup = false;
    this.hasSmsSent = false;
    this._user.TwoFA &= 1;
    this.updateUserProfile("2FA has been successfully disabled for SMS");
  }

  disableEmail2FA() {
    this.emailSetup = false;
    this.hasSent = false;
    this._user.TwoFA &= 2;
    this.updateUserProfile("2FA has been successfully disabled for Email");
  }

  updateUserProfile(message?: string) {
    this.userService.setUser(this._user).subscribe(result => {
      this.openSnackbar(message ? message : "2FA has been successfully updated", "");
    });
  }

  private openSnackbar(message: string, action: string) {
      if(action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
