import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MissionService } from "../MissionService";
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { PanelService } from "../panel.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NewPanelComponent } from '../new-panel/new-panel.component';
import { SurveyService } from '../survey.Service';
import { EditReportNameComponent } from '../reports/edit-report-name/edit-report-name.component';
import { ReportModel } from '../question.service';
import { NameSurveyFromScratchComponent } from '../name-survey-from-scratch/name-survey-from-scratch.component';
import { AuthenticationService, Organization, OrganizationModel, Group, UserGroup } from '../authentication.Service';
import { EditOrgNameComponent } from '../organization-detail/edit-org-name/edit-org-name.component';
import { version, instance } from "../../environments/environment";
import { ChatService } from '../chat.Service';
import { MediaService } from '../media.Service';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewGroupComponent } from '../security/new-group/new-group.component';

class OrgList {
  selected: boolean;
  icon: string;
  name: string;
  email: string;
  value: number;
  memberId: number;
  mediaId: number;
  mediaService: MediaService;

  constructor(selected: boolean, icon: string, name: string, email: string, value: number, memberId: number, mediaId: number,
    mediaService: MediaService) {
    this.selected = selected;
    this.icon = icon;
    this.name = name;
    this.email = email;
    this.value = value;
    this.memberId = memberId;
    this.mediaId = mediaId;
    this.mediaService = mediaService;
  }

  get media() {
    if (this._media != null) {
      return this._media == "" ? null : this._media;
    }

    if (this.mediaId == 0) {
      this._media = "";
      return null;
    }

    this.mediaService.getMediaById(this.mediaId).subscribe(
      result => {
        this._media = result;
      },
      error => {
        this._media = "";
      });
  }
  private _media = null;
}

@Component({
  selector: 'app-edit-toolbar',
  templateUrl: './edit-toolbar.component.html',
  styleUrls: ['./edit-toolbar.component.css'],
  providers: [MissionService]
})
export class EditToolbarComponent implements OnInit {
  confirmed = false;
  announced = false;
  id: number;
  ident: string;
  editType: string;
  name: string = "";
  subscription: Subscription | null = null;
  nameType: string;
  currentVersion: string;
  currentYear = new Date().getFullYear();
  currentInstance: string;
  messages = 0;
  organisation: OrganizationModel | null = null;
  group: Group | null = null;
  userGroup: UserGroup | null = null;
  showSurveyDashboard = false;
  showRespondents = false;
  isExpanded = false;

  organizations: Organization[] = [];
  orgList: OrgList[] = [];
  selectedOrg?: OrgList;
  lastLoggedIn: Date = new Date();
  ownOrganizations: boolean = false;
  private config: {
    version: string
  };
  private inTimer = false;

  @ViewChild('sidenav') public sidenav: any;
  @ViewChild('dummy') public dummy: ElementRef<any>;
  @ViewChild('usermenu') public usermenu: any;

  constructor(private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private missionService: MissionService,
    private actRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private panelService: PanelService,
    private surveyService: SurveyService,
    private authService: AuthenticationService,
    private router: Router,
    private chatService: ChatService,
    private location: Location,
    private mediaService: MediaService
  ) {
    this.currentVersion = version;
    this.lastLoggedIn = this.authService.lastLoggedIn();
    this.orgList = [];
    this.checkOrg();
    this.checkDetails();
  }

  get image(): string {
    return this.selectedOrg?.media;
  }

  currentUser(): OrgList {
    return new OrgList(true, 'account_circle', this.authService.getMemberName() ?? "", this.authService.getEmail() ?? "", - 1, -1, this.authService.getMediaId(), this.mediaService);
  }

  checkDetails() {
    this.missionService.missionConfirmed$.subscribe(
      () => {
      });

    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.showSurveyDashboard = true;
        } else {
          this.showSurveyDashboard = false;
        }
      });

    this.panelService.panelCount().subscribe(response => { this.showRespondents = response > 0 });
    this.chatService.getUnreadMessageCount().subscribe(response => { this.messages = response });
    this.currentVersion = version;
    this.currentInstance = instance;
    this.id = this.actRoute.snapshot.params.id;
    this.editType = this.actRoute.snapshot.params.type;
    let data = this.actRoute.data as any;
    if (this.editType == undefined) {
      this.name = data.value.title.toString();
      switch (this.name) {
        case "Survey Dashboard":
          break;
        case "Policy Group Editor":
          this.editType = "policygroup";
          break;
        case "User Group Editor":
          this.editType = "usergroup";
          break;
        default:
          this.editType = this.name.split(" ")[0]?.toLowerCase();
          break;
      }
    }

    this.displayName = true;
    this.nameType = "";
    switch (this.editType) {
      case "panel":
        this.nameType = "Panel";
        this.panelService.getPanel(this.id).subscribe(result => { this.name = result.Name; });
        break;
      case "template":
      case "panelist":
      case "securitykey":
      case "keyword":
      case "request":
      case "smshomekey":
      case "smshomekeyword":
      case "smshomewebhook":
      case "network":
      case "hmac":
        this.displayName = false;
        break;
      case "inbox":
      case "users":
      case "select":
      case "respondents":
      case "messaging":
      case "communication":
      case "system":
      case "global":
      case "data":
      case "webhook":
        this.displayName = true;
        break;
      case "basic":
      case "user":
      case "tinyurl":
      case "edituser":
      case "profile":
        this.name = data.value.title.toString();
        this.displayName = false;
        break;
      case "whatsapp":
        if (this.showBack) {
          this.displayName = false;
        }
        else {
          this.name = data.value.title.toString();
          this.displayName = true;
        }
        break;
      case "policygroup":
        this.nameType = "Group Policy";
        this.authService.getPolicyGroup(this.id).subscribe(result => { this.group = result; this.name = result.Name; });
        break;
      case "usergroup":
        this.nameType = "User Group";
        this.authService.getUserGroup(this.id).subscribe(result => { this.userGroup = result; this.name = result.Name });
        break;
      case "organization":
      case "adminorg":
      case "smshomeorg":
        this.authService.getOrganization(this.id).subscribe(result => { this.organisation = result; this.name = result.Name; });
        break;
      case "survey":
        this.nameType = "Survey";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => { this.name = result?.Name ?? this.name; });
        break;
      case "summary":
      case "delivery-summary":
        this.nameType = "Summary";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => { this.name = result.Name; });
        break;
      case "report":
      case "testreport":
        this.nameType = "Report";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => {
          if (result.ReportModel === "") {
            this.name = result.Name;
          }
          else {
            const temp = plainToClass(ReportModel, JSON.parse(result.ReportModel))[0];
            if (temp == null || temp.Title == null || temp.Title == "") {
              this.name = result.Name;
            }
            else {
              this.name = temp.Title;
            }
          }
        });
        break;
    }
  }

  onClick() {
    switch (this.editType) {
      case "report":
      case "testreport":
        this.missionService.announceMission({ data: { action: "back" } });
        break;
      case "survey":
        this.goToHome();
        break;
      default:
        this.missionService.announceMission("Back");
        break;
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  isHandsetLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
    .pipe(map(result => result.matches));

  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.config = require('../../assets/files/config.json')

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.http.get<{ version: string, connection: string }>('../../assets/files/config.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          let temp = this.snackBar.open("New version of the platform available", "Reload", {
            horizontalPosition: "center",
            verticalPosition: "top"
          });
          temp.afterDismissed().subscribe(() => {
            this.http
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          });
        }
      });
}

  gotoInbox() {
    this.router.navigate(['../inbox'])
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  isTester() {
    return this.authService.isAuthorized(['diy']);
  }

  goToHome() {
    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.router.navigate(['../surveydashboard']);
        } else {
          this.router.navigate(['../startfromtemplate']);
        }
      });
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  openBasicDialog(): void {
    let dialogRef;
    let opened = false;
    switch (this.editType) {
      case "panel":
        dialogRef = this.dialog.open(NewPanelComponent, { data: { id: this.id } });
        opened = true;
        break;
      case "survey":
      case "summary":
        dialogRef = this.dialog.open(NameSurveyFromScratchComponent, {
          data: { id: this.id }
        });
        opened = true;
        break;
      case "report":
      case "testreport":
        dialogRef = this.dialog.open(EditReportNameComponent, { data: { id: this.id } });
        opened = true;
        break;
      case "organization":
      case "adminorg":
        dialogRef = this.dialog.open(EditOrgNameComponent, { data: { id: this.id, model: this.organisation } });
        opened = true;
        break;
      case "policygroup":
        dialogRef = this.dialog.open(NewGroupComponent, { data: { id: this.group.GroupId, isPolicyGroup: true, group: this.group } });
        opened = true;
        break;
      case "usergroup":
        dialogRef = this.dialog.open(NewGroupComponent, { data: { id: this.userGroup.UserGroupId, isPolicyGroup: false, userGroup: this.userGroup } });
        opened = true;
        break;
    }

    if (opened) {
      dialogRef?.afterClosed().subscribe(result => {
        if (this.editType == "policygroup" && result) {
          this.group = result.data.group;
          this.name = result.data.group?.Name ?? this.name;
          return;
        }

        if (this.editType == "usergroup" && result) {
          this.userGroup = result.data.userGroup;
          this.name = result.data.userGroup ?? this.name;
          return;
        }

        if (this.editType == "survey" && result) {
          this.name = result.survey?.Name ?? this.name;
          return;
        }

        if ((this.editType == "survey" || this.editType == "summary") && result == 'no') {
          return;
        }

        if (result) {
          this.name = result.name;
        }

        if (this.editType === "report" || this.editType === "testreport") {
          this.missionService.announceMission({ data: { action: "update", result: result } });
        }
      });
    }
  }

  newSurveyFromScratchDialog(): void {
    const dialogRef = this.dialog.open(NameSurveyFromScratchComponent,
      {

      });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.survey?.SurveyID != 0) {
        this.router.navigate(['/surveyquestioneditor', result.survey.SurveyID, "survey"]);
      }

      console.log('The dialog was closed');
    });
  }

  isAdmin() {
    return this.authService.isAuthorized(['Admin']);
  }

  isSms() {
    return this.authService.isAuthorized(['sms']);
  }

  isAdvanced() {
    return this.authService.isAuthorized(['advanced']);
  }

  isDeveloper() {
    return this.authService.isAuthorized(['developer']);
  }

  onNext() {
    this.sidenav.toggle();
  }

  onCloseMenu() {
    this.sidenav.toggle();
  }

  toggleSideMenu() {
    this.sidenav.toggle();
  }

  checkOrg() {
    let userId = this.authService.getUserId();

    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      /*let m = this.orgList[0];
      m.selected = false;*/
      this.orgList = [];
      this.organizations = result;
      //this.orgList.push(m);
      let i = this.authService.currentOrganization();
      let found = false;
      for (let j = 0; j < this.organizations.length; j++) {
        let org = this.organizations[j];
        let o = new OrgList(false, org.OrganizationId == i ? 'supervised_user_circle' : 'supervisor_account', org.Name, "", org.OrganizationId, org.MemberId, org.MediaId, this.mediaService);
        if (org.RoleId == 5) {
          this.ownOrganizations = true;
        }

        /*if (org.OrganizationId === i) {
          o.selected = true;
          this.selectedOrg = o;
          this.orgList[0].selected = false;
          this.orgList[0].icon = 'person';
        }*/
        if (o.value == i) {
          found = true;
          this.selectedOrg = o;
        }
        else {
          this.orgList.push(o);
        }
      }

      this.orgList.splice(0, 0, this.currentUser());
      if (!found) {
        this.selectedOrg = this.currentUser();
      }

      this.orgList.push(new OrgList(false, 'add', 'Create an Organization', "", 0, -1, 0, this.mediaService));
      if (this.ownOrganizations) {
        this.orgList.push(new OrgList(false, 'business', 'Manage Organizations', "", -2, this.orgList[1].memberId, 0, this.mediaService));
      }
    });
  }

  toggleUserMenu() {
    if (!this.usermenu.opened) {
      this.checkOrg();
    }

    this.usermenu.toggle();

  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  @Input()
  get displayName(): boolean {
    return this._displayName;
  }
  set displayName(value: boolean) {
    this._displayName = value;
  }
  private _displayName: boolean = false;

  @Input()
  get showBack(): boolean {
    return this._showBack;
  }
  set showBack(value: boolean) {
    this._showBack = value;
    this.checkDetails();
  }
  private _showBack: boolean = true;

  get username(): string {
    return this.authService.getMemberName() ?? "";
  }

  isImpersonating() {
    return this.authService.impersonate() ? "I" : "";
  }

  stopImpersonating() {
    this.authService.stopImpersonating();
  }

  makeSelection(i: number, $event: any) {
    this.inTimer = true;
    this.selectedOrg = this.orgList[i];
    let index = this.selectedOrg.value;
    switch (index) {
      case 0:
        this.router.navigate(["/neworganization"]);
        break;
      case -1:
        this.authService.setOrganization(this.location.path(), this.selectedOrg.value);
        this.toggleUserMenu();
        break;
      case -2:
        this.router.navigate(["/profile", this.orgList[i].memberId]);
        break;
      default:
        for (let i = 0; i < this.orgList.length; i++) {
          this.orgList[i].selected = false;
        }

        this.authService.setOrganization(this.location.path(), this.selectedOrg.value);

        this.toggleUserMenu();
        break;
    }
    this.inTimer = false;
  }

  isOrganization() {
    return this.authService.currentOrganization();
  }
}
