import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService, TFAModel } from '../../authentication.Service';
import { FeatureTourDialogComponent } from '../../feature-tour-dialog/feature-tour-dialog.component';
import { SurveyService } from '../../survey.Service';
import { UserProfile, UserService } from '../../user.Service';
import { TfentryComponent } from '../two-factor-autentication/tfentry/tfentry.component';

@Component({
  selector: 'app-two-factor-test',
  templateUrl: './two-factor-test.component.html',
  styleUrls: ['./two-factor-test.component.css']
})
export class TwoFactorTestComponent {

  makeSelection: boolean = false;
  formGroup: FormGroup = new FormGroup({});
  errorMsg: string = "";
  userId: string = "";
  profile: UserProfile;
  label: string = "";
  selectionType: string = "email";
  sent: boolean = false;
  code: string = "";

  @ViewChild('tfentry') tfentry: TfentryComponent;

  constructor(private authenticationService: AuthenticationService, private userService: UserService,
    private snackbar: MatSnackBar,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private surveyService: SurveyService,
    public dialog: MatDialog,
) {
    this.userId = this.authenticationService.getUserId().toString();
    this.formGroup = new FormGroup({
      authentication: new FormControl(),
      type: new FormControl("email")
    });
    this.userService.getCurrentUser().subscribe(
      result => {
        this.profile = result;
        switch (this.profile.TwoFA) {
          case 1:
            break;
          case 2:
            this.formGroup.controls.type.setValue("sms");
            this.selectionType = "sms";
            break;
          case 3:
            this.makeSelection = true;
            break;
        }

        this.checkType();
      }
    );
  }

  checkType() {
    if (this.formGroup.controls.type.value == "email") {
      this.label = "Use Email to Verify";
      this.selectionType = "email";
    }
    else {
      this.label = "Use SMS to Verify";
      this.selectionType = "sms";
    }
  }

  send() {
    this.tfentry?.setFocusToControl();
    if (this.formGroup.controls.type.value == "email") {
      this.authenticationService.sendEmail2FA().subscribe(result => {
        this.sent = true;
        this.code = result;
      });
      return;
    }

    this.authenticationService.sendSms2FA().subscribe(result => {
      this.sent = true;
      this.code = result;
    })
  }

  onSubmit() {
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          let model = new TFAModel();
          model.IpAddress = result.ip;
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.onSubmita(model);
        },
        error: (error) => {
          let model = new TFAModel();
          model.IpAddress = "Unknown";
          model.DeviceType = this.deviceService.getDeviceInfo()?.userAgent ?? "Unknown";
          model.Code = this.code;
          this.onSubmita(model);
        }
      });
  }

  onSubmita(model: TFAModel) {
    this.authenticationService.check2FA(model).subscribe({
      next: (result) => {
        if (this.authenticationService.isAuthorized(['Admin'])) {
          this.router.navigate(['../system-dashboard']);
        }
        else if (this.authenticationService.isAuthorized(['developer'])) {
          this.router.navigate(['../smshome/keys']);
        }
        else {
          this.surveyService.getSurveyCount()
            .subscribe({
              next: (response) => {
                if (response > 0) {
                  this.router.navigate(['../surveydashboard']);
                  if (!localStorage.getItem('homeTourDone')) {
                    const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
                  }
                } else {
                  this.router.navigate(['../startfromtemplate']);
                  if (!localStorage.getItem('homeTourDone')) {
                    const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
                  }
                }
              },
              error: (error) => {
                if (error.status === 401) {
                  this.router.navigate(['../']);
                }
              }
            });
        }
      },
      error: (error) => {
        this.openSnackbar("There was a problem with the Access Code", "Cancel");
      }
    });
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

}
