<div class="twoFA-main-div">
  <div class="twoFA-card">
    <div *ngIf="user" style="width:75%;margin: 5px auto">
      <app-two-factor-autentication #tfa (cancel)="cancel()" [user]="user" [setup]="true"></app-two-factor-autentication>
      <div *ngIf="canContinue()" [@fadeInOnEnter] [@fadeOutOnLeave]>
        <mat-divider></mat-divider>
        <div style="margin-top:5px">
          <button mat-raised-button color="primary" type="button" (click)="continue()">Continue to Login</button>
        </div>
      </div>
    </div>
  </div>

</div>
