import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { fromEvent, takeUntil, Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthenticationService, RegistrationModel } from '../../authentication.Service';
import { UserService } from '../../user.Service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { PasswordValidator } from '../../password-validator/password-validator';
import { SystemService } from '../../system.Service';

@Component({
  selector: 'app-newsignup',
  templateUrl: './newsignup.component.html',
  styleUrl: './newsignup.component.css',
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 500 }),
    fadeInAnimation({ anchor: 'enter', duration: 1000 }),
    fadeInAnimation({ anchor: 'errorFadein', duration: 1000, delay: 100 })
  ]
})
export class NewsignupComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  start: boolean = false;
  fadeInLeftState = false;
  model: RegistrationModel = null;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  unavailable: boolean = false;
  emailValid: boolean = false;
  nameValid: boolean = false;
  passwordValid: boolean = false;
  usernameValid: boolean = false;
  tellmemore: boolean = false;
  showCaptcha: boolean = false;
  siteKey: string = "6LdvQ5AqAAAAAHInEePSGKXIs6O_WlDbeSbdmqbx";
  pattern = '^[a-zA-Z0-9_.+-]+[a-zA-Z0-9-]+$';

  alreadyExists: boolean = false;
  hasErrors: boolean = false;
  errorMessages: string[] = [];
  registered: boolean = false;
  submitted: boolean = false;

  showName: boolean = false;
  showPassword: boolean = false;
  showUsername: boolean = false;
  showSignup: boolean = false;
  hide: boolean = true;
  hide2: boolean = true;
  passwordValidator: PasswordValidator;
  passwordProgress: number = 0;
  formReady: boolean = false;

  emailControl: FormControl;
  nameControl: FormControl;
  passwordControl: FormControl;
  usernameControl: FormControl;
  emailControlName: string = "";
  nameControlName: string = "";
  passwordControlName: string = "";
  usernameControlName: string = "";
  suggestions: string[] = [];
  suggestion: string = "";

  captchaLevel: number = 1.9

  @ViewChild("name") public name: ElementRef;
  @ViewChild("password") public password: ElementRef;
  @ViewChild("username") public username: ElementRef;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private actRoute: ActivatedRoute,
    private userService: UserService,
    private socialService: SocialAuthService,
    private authenticationService: AuthenticationService,
    private systemService: SystemService
  ) {
    this.passwordValidator = new PasswordValidator({
      minLength: 8,
      maxLength: 20,
      requireUppercase: true,
      requireLowercase: true,
      requireSpecialChars: true,
      combineErrors: true
    });
    this.systemService.getById("google:captchaLevel").subscribe(
      result => { this.captchaLevel = result.Value; }
    );
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.errorMessages.push("");
    this.errorMessages.push("");
    let random = Math.random().toString().replace('.', '');
    this.emailControl = new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]);
    this.nameControl = new FormControl("", [Validators.required]);
    this.passwordControl = new FormControl("", [Validators.required]);
    this.usernameControl = new FormControl("", [Validators.required, Validators.pattern(this.pattern)]);
    this.formGroup = new FormGroup({});
    this.emailControlName = "ddde" + random;
    this.nameControlName = "dddn" + random;
    this.passwordControlName = "dddp" + random;
    this.usernameControlName = "dddu" + random;
    this.formGroup.addControl(this.emailControlName, this.emailControl);
    this.formGroup.addControl(this.nameControlName, this.nameControl);
    this.formGroup.addControl(this.passwordControlName, this.passwordControl);
    this.formGroup.addControl(this.usernameControlName, this.usernameControl);
    if (this.actRoute.snapshot.params.id != undefined) {
      this.emailControl.setValue(this.actRoute.snapshot.params.id);
      this.checkChanges();
    }

    this.formGroup.valueChanges.subscribe(x => {
      this.checkChanges();
    });
    this.formReady = true;
  }

  setFocus(elementName: string, valid: boolean) {
    if (!valid) {
      return;
    }

    let element = null;
    switch (elementName) {
      case "name":
        if (this.emailControl.valid) {
          this.userService.checkEmailAddress(this.emailControl.value).subscribe(
            result => {
              this.hasErrors = true;
              this.errorMessages[0] = "The email address " + this.emailControl.value + " is already in use by an account.";
              this.alreadyExists = true;
              this.emailValid = false;
              return;
            },
            error => {
              this.showName = true;
              this.alreadyExists = false;
              setTimeout(() => {
                while (true) {
                  if (this.name != undefined) {
                    this.name?.nativeElement.focus();
                    return;
                  }
                }
              });
            });
        }

        break;
      case "password":
        this.showPassword = true;
        setTimeout(() => {
          while (true) {
            if (this.password != undefined) {
              this.password?.nativeElement.focus();
              return;
            }
          }
        });
        break;
      case "username":
        this.showUsername = true;
        setTimeout(() => {
          while (true) {
            if (this.username != undefined) {
              this.username?.nativeElement.focus();
              return;
            }
          }
        });
        break;
      case "checkusername":
        this.checkUsernameTimer();
        break;
    }

    return;
  }

  checkUsernameTimer() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      this.checkUsername();
    }, 500);
  }

  checkUsername() {
    if (!this.usernameControl.valid || this.usernameControl.value.length < 5) {
      this.hasErrors = true;
      this.errorMessages[3] = "The username is invalid. Must be at least 5 characters long and contain only alphanumeric characters.";
      this.usernameValid = false;
      this.suggestion = "";
      return;
    }

    this.userService.checkUsername(this.usernameControl.value).subscribe({
      next: (result) => {
        this.hasErrors = true;
        this.errorMessages[3] = "Username " + this.usernameControl.value + " is already in use.";
        this.suggestions = result;
        this.suggestion = "";
        for (let i = 0; i < this.suggestions.length; i++) {
          this.suggestion += (i + 1 == this.suggestions.length ? " or " : i == 0 ? "" : ", ") + this.suggestions[i];
        }

        this.alreadyExists = true;
        this.usernameValid = false;
        return;
      },
      error: (error) => {
        switch (error.status) {
          case 409:
            this.hasErrors = true;
            this.errorMessages[3] = "Username " + this.usernameControl.value + " is not available.";
            this.alreadyExists = true;
            this.usernameValid = false;
            this.suggestion = "";
            return;
          case 404:
            this.errorMessages[3] = "";
            this.alreadyExists = false;
            this.usernameValid = this.showUsername;
            this.suggestion = "";
            this.checkErrors();
            return;
          default:
            this.hasErrors = true;
            this.errorMessages[3] = "There is a problem with the sign up process - please try again later.";
            this.suggestion = "";
            this.alreadyExists = true;
            this.usernameValid = false;
        }
      }
    });
  }

  checkChanges() {
    if (!this.emailControl.valid) {
      this.hasErrors = true;
      if (this.emailControl.value.length > 5) {
        this.errorMessages[0] = "The email address is either invalid or already in use.";
      }

      if (this.emailControl.value.length < 6) {
        this.errorMessages[0] = '';
      }


      this.emailValid = false;
    }
    else {
      this.hasErrors = false;
      this.errorMessages[0] = "";
      this.emailValid = true;
    }

    if (this.showName && !this.nameControl.valid) {
      this.hasErrors = true;
      this.errorMessages[1] = "Please supply a name that you would like to known by";
      this.nameValid = false;
    }
    else {
      this.errorMessages[1] = "";
      this.nameValid = this.showName;
    }

    if (this.showPassword) {
      if (!this.passwordControl.valid) {
        this.hasErrors = true;
        this.errorMessages[2] = "Please supply a valid password";
        this.passwordValid = false;
        this.passwordProgress = 0;
      }
      else {
        let pwResult = this.passwordValidator.validate(this.passwordControl.value);
        if (!pwResult.valid) {
          this.errorMessages[2] = pwResult.errors[0].message;
          this.passwordValid = false;
          let pg = 4 - pwResult.errors[0].code.split('|').length;
          if (pg > 0) {
            pg *= 25;
          }

          this.passwordProgress = pg;
       }
        else {
          this.errorMessages[2] = "";
          this.passwordValid = this.showPassword;
          this.passwordProgress = 100;
        }
      }
    }
    else {
      this.errorMessages[2] = "";
      this.passwordValid = this.showPassword;
    }

    if (this.showUsername) {
      this.checkUsernameTimer();
    }

    return this.checkErrors();
  }

  checkErrors(): boolean {
    let he = false;
    for (let i = 0; i < this.errorMessages.length; i++) {
      if (this.errorMessages[i] != "") {
        he = true;
        break;
      }
    }

    this.hasErrors = he;
    return true;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    if (this.actRoute.snapshot.params.id != undefined) {
      this.emailControl.setValue(this.actRoute.snapshot.params.id);
      this.checkChanges();
      if (this.emailValid) {
        this.showName = true;
      }
    }

    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  canSignUp(): boolean {
    return false;
  }

  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  ngOnInit(): void {
  }

  checkName(event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const name = this.emailControl.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.testName(name);
    }, 500);
  }

  testName(name: string) {
  }

  submitForm(): boolean {
    this.model = new RegistrationModel();
    this.model.Email = this.emailControl.value;
    let names = this.nameControl.value.split(' ');
    this.model.FirstName = names[0];
    let last = '';
    for (let i = 1; i < names.length; i++) {
      last += " " + names[i];
    }

    this.model.LastName = last;
    this.model.Password = this.passwordControl.value;
    this.model.Username = this.usernameControl.value;
    this.model.Tandc = true;
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          this.model.IpAddress = result.ip;
          this.signupProcess();
        },
        error: (error) => {
          this.registerUser();
        }
      });

    return false;
  }

  resolvedCaptcha(event: any) {
    this.handleToken(2, event.token);
  }

  erroredCaptcha(event: any) {
    let a = event;
  }

  signupProcess() {
    this.recaptchaV3Service.execute('signup').subscribe((token) => this.handleToken(3, token));
  }

  handleToken(version: number, token: string) {
    this.authenticationService.testRcToken(version, token, this.model.IpAddress).subscribe(
      {
        next: (result) => {
          if (result.success && (version == 2 || result.score <= this.captchaLevel)) {
            if (version == 3) {
              this.showCaptcha = true;
              return;
            }
          }

          this.registerUser();
        },
        error: (error) => {
          this.unavailable = false;
          this.errorMessages[0] = "Unable to register at the time";
          return;
        }
      });
  }

  registerUser() {
    this.registered = false;
    this.submitted = true;
    this.showCaptcha = false;
    this.authenticationService.register(this.model).subscribe({
      next: (result) => {
        this.registered = true;
        this.submitted = false;
      },
      error: (error) => {
        this.unavailable = false;
        if (error.status === 404) {
          this.errorMessages[4] = "The Platform is not currently available for registration, please come back and try again";
          this.unavailable = true;
        }
        else if (error.status === 400) {
          // cannot register
          this.errorMessages[4] = "Unable to register at this time please check the email address";
        }
        else if (error.status === 409) {
          // Duplicate details
          this.errorMessages[4] = "These details may already be in use";
        } else {
          console.error(error.message);
          this.errorMessages[4] = error.message;
        }

        this.checkErrors();
        this.submitted = false;
      }
    });
  }

  updateAccount() {
    this.model = new RegistrationModel();
    this.model.Email = this.emailControl.value;
    let names = this.nameControl.value.split(' ');
    this.model.FirstName = names[0];
    let last = '';
    for (let i = 1; i < names.length; i++) {
      last += " " + names[i];
    }

    this.model.LastName = last;
    this.model.Password = this.passwordControl.value;
    this.model.Username = this.usernameControl.value;
    this.model.Tandc = true;
    this.registered = false;
    this.submitted = true;
    this.tellmemore = false;
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          this.model.IpAddress = result.ip;
          this.subUpdateAccount();
        },
        error: (error) => {
          this.subUpdateAccount();
        }
      });
  }

  subUpdateAccount() {
    this.authenticationService.tellMeLater(this.model).subscribe({
      next: (result) => {
        this.hasErrors = false;
        this.errorMessages = [];
        this.tellmemore = true;
      },
      error: (error) => {
        this.hasErrors = false;
        this.errorMessages = [];
        this.tellmemore = true;
      }
    });
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

}
