import { Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../authentication.Service';
import { UserProfile, UserService } from '../../../user.Service';
import { TwoFactorAutenticationComponent } from '../two-factor-autentication.component';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { SurveyService } from '../../../survey.Service';
import { Router } from '@angular/router';
import { ZendeskUtil } from '../../../utils';

@Component({
  selector: 'app-two-factor-setup',
  templateUrl: './two-factor-setup.component.html',
  styleUrl: './two-factor-setup.component.css',
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 100 }),
    fadeInAnimation({ anchor: 'enter', duration: 1000 }),
    fadeInAnimation({ anchor: 'errorFadein', duration: 1000, delay: 100 })
  ]
})
export class TwoFactorSetupComponent {

  user: UserProfile;
  zendeskCheck: string[] = [
    "https://",
    "https://localhost",
    "https://staging.",
    "https://pg.",
    "https://ire.",
    "https://sg.",
    "https://ldn.",
    "https://ams.",
    "https://lbg"
  ];

  @ViewChild('tfa') tfa: TwoFactorAutenticationComponent;

  constructor(private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private surveyService: SurveyService) {
    userService.getCurrentUser().subscribe(
      result => { this.user = result }
    );
  }

  cancel() {

  }

  canContinue(): boolean {
    return this.tfa?.emailSetup || this.tfa?.smsSetup;
  }

  continue() {
    let page = '';
    if (this.authenticationService.isAuthorized(['Admin'])) {
      page = '/system-dashboard';
      this.signIntoZenDesk(page);
    }
    else if (this.authenticationService.isAuthorized(['developer'])) {
      page = '/smshome/keys';
      this.signIntoZenDesk(page);
    }
    else {
      this.surveyService.getSurveyCount()
        .subscribe({
          next: (response) => {
            if (response > 0) {
              page = '/surveydashboard';
            } else {
              page = '/startfromtemplate';
            }

            this.signIntoZenDesk(page);
          },
          error: (error) => {
            if (error.status === 401) {
              this.router.navigate(['../']);
            }
          }
        });
    }
  }

  private signIntoZenDesk(redirectTo: string) {
    let rt = window.location.href.toString();
    for (let i = 0; i < this.zendeskCheck.length; i++) {
      if (rt.startsWith(this.zendeskCheck[i])) {
        this.router.navigate(['..' + redirectTo]);
        return;
      }
    }

    this.authenticationService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, redirectTo);
      },
      error => {
        console.error("There was a problem with the login for Zendesk:" + error)
      });
  }

}
