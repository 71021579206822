import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../authentication.Service';
import { UserService } from '../../user.Service';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.css']
})
export class ChangeUsernameComponent implements OnInit {
  errorMsg: string = "";
  success: boolean = false;
  formGroup: FormGroup = new FormGroup({});
  timeout: any | null = null;
  newUser = false;
  title = "Change your Username";
  buttonTitle = "Change";
  isOrganization = false;
  pattern = '^[a-zA-Z0-9_.+-]+[a-zA-Z0-9-]+$';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangeUsernameComponent>,
    private authService: AuthenticationService,
    private userService: UserService) { }

  ngOnInit(): void {
    if (this.data.id === 0) {
      this.newUser = true;
      this.title = "Create User Template";
      this.buttonTitle = "Create";
    }

    switch (this.data.type) {
      case "organization":
        this.title = "Change your Organization Name";
        this.isOrganization = true;
        break;
    }

    this.formGroup = new FormGroup({
      username: new FormControl("", [Validators.required, Validators.pattern(this.pattern)])
    });
  }

  checkName(event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const name = this.formGroup.controls.username.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.testName(name);
    }, 500);
  }

  setError(abstractControl: any, name: string, value: any) {
    let control = abstractControl as FormControl;
    if (control.hasError(name)) {
      if (value == null) {
        delete control.errors[name];
      }
      else {
        control.errors[name] = 'true'
      }

      control.updateValueAndValidity();
      return;
    }

    if (value == null) {
      return;
    }

    control.setErrors({ ...(control.errors || {}), name: value });
    control.updateValueAndValidity();
  }

  testName(name: string) {

    if (name.length < 3 || name.toLowerCase() == "admin") {
      this.success = false;
      this.setError(this.formGroup.controls.username, 'incorrect', true);
      return;
    }

    if (this.isOrganization) {
      this.authService.findOrganizations(name).subscribe({
        next: (result) => {
          this.setError(this.formGroup.controls.username, 'incorrect', true);
          this.success = false;
        },
        error: (error) => {
          this.setError(this.formGroup.controls.username, 'incorrect', null);
          this.success = true;
          this.userService.checkUsername(name).subscribe(result => {
            this.success = false;
            this.setError(this.formGroup.controls.username, 'incorrect', true);
          });
        }
      });
      return;
    }

    this.userService.checkUsername(name).subscribe({
      next: (result) => {
        this.success = false;
        this.setError(this.formGroup.controls.username, 'incorrect', true);
      },
      error: (error) => {
        this.success = true;
        this.authService.findOrganizations(name).subscribe(result => {
          this.setError(this.formGroup.controls.username, 'incorrect', true);
          this.success = false;
        },
          error => {
            this.success = true;
            this.setError(this.formGroup.controls.username, 'incorrect', null);
          });
      }
    });
  }

  onSubmit() {
    if (this.data) {
      if (this.data.id === 0) {
        this.authService.registerTemplate(this.formGroup.controls.username.value).subscribe(
          result => {
            this.dialogRef.close({ userid: result });
          });
      }
      else {
        if (this.isOrganization) {
          this.userService.changeOrgName(this.data.id, this.formGroup.controls.username.value).subscribe();
        }
        else {
          this.userService.changeUsername(this.data.id, this.formGroup.controls.username.value).subscribe();
        }

        this.dialogRef.close({ username: this.formGroup.controls.username.value });
      }
    }
    else {
      if (this.isOrganization) {
        this.userService.changeOrgName(this.data.id, this.formGroup.controls.username.value).subscribe();
        this.dialogRef.close({ username: this.formGroup.controls.username.value });
        return false;
      }

      this.userService.change(this.formGroup.controls.username.value).subscribe(
        result => {
          this.authService.setAccessToken(result.Token);
          this.dialogRef.close({ username: this.formGroup.controls.username.value });
        }
      );
    }

    return false;
  }

}
