<app-newtoolbar [menu]="menu"></app-newtoolbar>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div id="home" class="header">
    <div id="carouselHome" class="carousel carousel-dark slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="0" class="active" aria-current="true"></button>
        <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
          <div class="headerContent">
            <div class="left" [@leftIn]="start">
              <h1 style="font-size:2.4em">
                Powerful enough for Researchers...
              </h1>
              <h2>
                Simple enough for Everyone!
              </h2>
              <p>
                Access to a set of unlimited tools for survey creation and feedback analysis!
              </p>
              <p>
                <span class="strong">Get started with a Free Account</span>
              </p>
              <div [formGroup]="formGroup" class="fieldRow">
                <div>
                  <mat-form-field appearance="outline" class="fullWidth">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Email Address" formControlName="email">
                  </mat-form-field>
                </div>
                &nbsp;
                <div>
                  <button mat-raised-button (click)="startForFree(false)" color="primary">Start for Free</button>
                </div>
              </div>
            </div>
            <div class="right" [@rightIn]="start">
              <img src="assets/img/sign-in-image@2x.png" alt="Acces to a set of unlimited tools for survey creation and feedback analysis" class="image picture" />
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="headerContent" style="display:flex;flex-flow:column">
            <h1>
              A survey solution for for everyone!
            </h1>
            <ul>
              <li>Tired of paying monthly for survey software that you don't need every month?</li>
              <li>Need more than one user but the costs are just growing?</li>
              <li>Frustrated at having to upgrade users to the next level to get the functionality you need?</li>
              <li>Budget is getting tighter and you need to become more cost effective?</li>
            </ul>
            <p>
              DIY Surveys is license free for any number of users, all functionlity included, just pay for what you use when you use if!
            </p>
            <p>
              <span class="strong">Get started with a Free Account</span>
            </p>
            <div [formGroup]="formGroup" class="fieldRow">
              <div>
                <mat-form-field appearance="outline" class="fullWidth">
                  <mat-label>Email Address</mat-label>
                  <input matInput placeholder="Email Address" formControlName="email">
                </mat-form-field>
              </div>
              &nbsp;
              <div>
                <button mat-raised-button (click)="startForFree(false)" color="primary">Start for Free</button>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="headerContent">
            <div class="left" [@leftIn]="start">
              <h1>
                We are Connected!
              </h1>
              <p>
                DIY Surveys offer the only service that connects into: Email, SMS, Chat Solutions – WhatsApp, Facebook Messaging
              </p>
              <p>
                <span class="strong">Get started with a Free Account</span>
              </p>
              <div [formGroup]="formGroup" class="fieldRow">
                <div>
                  <mat-form-field appearance="outline" class="fullWidth">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Email Address" formControlName="email">
                  </mat-form-field>
                </div>
                &nbsp;
                <div>
                  <button mat-raised-button (click)="startForFree(false)" color="primary">Start for Free</button>
                </div>
              </div>
            </div>
            <div class="right" [@rightIn]="start">
              <img src="../../assets/img/AllChannels.png" alt="We are connected to all channels (Email, SMS, WhatsApp, etc.)" style="height:250px;width:auto" class="image picture" />
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="headerContent">
            <div class="left" style="width:80%" [@leftIn]="start">
              <h1>
                An Enterprise Solution
              </h1>
              <p>
                Our cloud solution includes:
              </p>
              <ul>
                <li>A leading-edge front end</li>
                <li>Multiple channels for survey invitations and two-way messaging using Web, WhatsApp and SMS</li>
                <li>A worldwide audience accessible in an instant</li>
                <li>The ability to import and export data in different formats over multiple data transports (FTP, SFTP, FTPS, HTTP, etc.)</li>
                <li>All based on 'OneScript' our research ready scripting language</li>
                <li>Available anywhere in the world</li>
              </ul>
            </div>
            <div class="right" [@rightIn]="start">
              <img src="../../assets/img/Enterprise.png" alt="An Enterprise solution with enterprise features" style="height:250px;width:auto" class="image picture" />
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <div id="surveys" class="section">
    <div class="sectionContent">
      <h1>Create a survey in minutes</h1>
      <h2>It's as easy as 1-2-3</h2>
    </div>
  </div>
  <div class="section">
    <div class="simple-row">
      <div class="wd-25-per margin-5"></div>
      <div class="number">1</div>
      <div class="wd-25-per margin-5"><mat-divider></mat-divider></div>
      <div class="number">2</div>
      <div class="wd-25-per margin-5"><mat-divider></mat-divider></div>
      <div class="number">3</div>
      <div class="wd-25-per margin-5"></div>
    </div>
    <div>
      &nbsp;
    </div>
    <div class="simple-row">
      <div class="wd-30-per"></div>
      <div>
        <h2>Use one of our survey templates or build your own</h2>
      </div>
      <div class="wd-15-per"></div>
      <div>
        <h2>Your own respondents or buy respondents to meet your needs</h2>
      </div>
      <div class="wd-15-per"></div>
      <div>
        <h2>Choose the best channel for your audience</h2>
      </div>
      <div class="wd-30-per"></div>
    </div>
    <div>
    </div>
  </div>
  <div class="section">
    <div class="sectionContent">
      <div class="sectionCard">
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step1')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report1.svg" alt="templates" width="40" />
          </div>
          <span class="strong mb-20">Survey Templates</span>
          <mat-card-content class="mb-20">
            NPS, CES, CSAT Combination, Event Feedback, Coffee shop, Garage Service Survey…
          </mat-card-content>
        </mat-card>
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step2')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report2.svg" alt="sample" width="40" />
          </div>
          <span class="strong mb-20">
            Buy or Upload
          </span>
          <mat-card-content class="mb-20">
            Upload your respondents or use our - <span [countUp]="[335,10000]"></span>+ million engaged respondents, <span [countUp]="[130,10000]"></span>+ countries, <span [countUp]="[50,10000]"></span>+ demographics
          </mat-card-content>
        </mat-card>
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step3')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report3.svg" alt="channel" width="40" />
          </div>
          <span class="strong mb-20">
            Choose a Channel
          </span>
          <mat-card-content class="mb-20">
            Choose from Email, SMS, WhatsApp and many other channels to get your feedback
          </mat-card-content>
        </mat-card>
      </div>
      <div id="step1">
        <div class="wa-main-container">
          <div style="display:flex;min-width:50px">
            <img src="../../assets/img/report4.svg" alt="reports" width="40" />
            Results Analysis
            <button mat-icon-button aria-label="Example icon button with a bookmark icon" (click)="scrollTo('step4')">
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div>
        View the data, edit reports ready for presentation, or export your data to play with
      </div>
    </div>
  </div>
  <div style="background-color:white;margin-top:-350px;padding-top:350px">
    <div class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="leftImage" style="width:80%" [@leftIn]="start && p1">
          <div class="display-flex">
            <span class="number">1</span>&nbsp;
            <h2>Use one of our survey templates</h2>
          </div>
          <h1>Rely on our Research Experience</h1>
          <p>
            Access to survey templates to save you time and let you focus on your audience.
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer a full question editor with simple look and feel controls and drag and drop controls.
          </p>
          <div id="step2"></div>
        </div>
        <div class="right" [@rightIn]="start && p1" (appear)="p1 = true">
          <img class="picture" alt="Reply on our reserach experience" src="../../assets/img/step1.svg" />
        </div>
      </div>
    </div>
    <div id="audience" class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="left" [@leftIn]="start && p2">
          <img class="picture" alt="Buy respondents to meet your needs" src="../../assets/img/landing-page-image.png" />
        </div>
        <div class="rightImage" style="width:80%" [@rightIn]="start && p2" (appear)="p2 = true">
          <div style="display:flex;">
            <span class="number">2</span>&nbsp;
            <h2>
              Upload your own respondents or buy respondents to meet your needs
            </h2>
          </div>
          <h1>
            Reach the right people
          </h1>
          <p>
            335+ million people, 130+ countries, 50+ demographics.
            Select target audiences quickly, easily and at a low price.
            Choose the demographics that you require: age, gender, region, income and much much more (over 50+ attributes).
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For those with their own panels you can simply upload through our easy to use UI,
            or set up regular automated uploads through our comprehensive Data Import and Export facilities and our fully customizable API.
          </p>
        </div>
      </div>
    </div>
    <div id="step3" class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="leftImage" style="width:80%" [@leftIn]="start && p3">
          <div style="display:flex;">
            <span class="number">3</span>&nbsp;
            <h2>
              Choose the best channel to invite your repondents to take your surveys
            </h2>
          </div>
          <h1>
            We are Connected!
          </h1>
          <p>
            DIY Surveys is unique in that it provides Email, SMS and WhatsApp communication channels to deliver your surveys globally, you choose!
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            We offer the ability to plug into SMPP, custom API's and any service that provide the ability to send a message, either as a invitation service or a two-way research service.
          </p>
        </div>
        <div class="right" [@rightIn]="start && p3" (appear)="p3 = true">
          <img class="picture" style="width:100%" alt="We are connected" src="../../assets/img/AllChannels.png" />
        </div>
      </div>
    </div>
    <div id="step4" class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="left" [@leftIn]="start && p2">
          <img class="picture" alt="Understand your feedback" src="../../assets/img/Reporting.png" />
        </div>
        <div class="rightImage" style="width:80%" [@rightIn]="start && p2" (appear)="p2 = true">
          <div style="display:flex;">
            <h2>
              Results Analysis
            </h2>
          </div>
          <h1>
            Integrated Reports
          </h1>
          <p>
            View your survey data how you want. We provice fast and simple charts and tables in an Online results viewer and report
            builder with auto Excel and Pdf exports, or you can export your data and do advanced analysis and reporting in your preferred statistics package.
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer full integration with real time updates to schedules and batch updates in any format to any platform.
          </p>
        </div>
      </div>
    </div>
    <div id="pricing" class="section" style="background-color: #F6F6F6">
      <h1>
        Pricing
      </h1>
      <div class="sectionContent" style="display: flex; width: 80%; margin: 0px auto;">
        <div class="leftImage" style="margin-right:20px;width:45%" [@leftIn]="start && p2">
          <h2>
            Cash Customers
          </h2>
          <p>
            DIY Users are what we call Cash customers and can use their Credit/Debit cards to top up their account balance.
          </p>
          <p>
            When following the 3-step survey launch process, the platform estimates the costs based on the parameters you set, provides a cost and then if you are happy it meets your needs and your account credit is sufficient your survey can be launched.
          </p>
          <ul>
            <li>No license fees.</li>
            <li>Pay per survey complete £0.30p.</li>
            <li>Pay per SMS or WhatsApp invitation - cost vary by country but global average is £0.05p.</li>
            <li>Standard support and the use of knowledge base (How to) is included free.</li>
          </ul>
        </div>
        <div class="rightImage" style="width:50%" [@rightIn]="start && p2" (appear)="p2 = true">
          <h2>Credit Customers</h2>
          <p>
            Larger customer with higher volume usage such as Market Researchers, Consultants, In-house Brand Insight teams, and CX / VoC platform providers are verified as Credit users.
            This means that we will invoice your elected Accounts dept monthly for your usage unless, of course, you request that your users have cash accounts.
          </p>
          <ul>
            <li>No license fees unless your usage requires premium support in which case annual license fees well be agreed on a case-by-case basis.</li>
            <li>Pay per survey complete £0.30p basic but with volume breaks for scale of use.</li>
            <li>Pay per SMS or WhatsApp invitation - costs vary by country but global average is £0.05p, again volume breaks are available dependent on scale of use.</li>
            <li>Standard support and use of the knowledge base (How to) is included free.</li>
          </ul>
        </div>
      </div>
    </div>
    <app-newfooter></app-newfooter>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
  <div id="home-mobile" class="section-mobile-header">
    <div id="carouselHome" class="carousel carousel-dark slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
          <div class="sectionContent-mobile column-flex">
            <div class="left padding-0" [@leftIn]="start">
              <img src="assets/img/sign-in-image@2x.png" class="fullWidth picture" />
            </div>
            <div class="rightImage fullWidth" [@rightIn]="start">
              <span style="color: #09215c; font-size: 2.5rem; font-weight: 400;">
                Powerful enough for Researchers...
              </span>
              <h2>
                Simple enough for Everyone!
              </h2>
              <p>
                Access to a set of unlimited tools for survey creation and feedback analysis!
              </p>
              <p>
                <span class="strong">Get started with a Free Account</span>
              </p>
              <div [formGroup]="formGroup" class="fieldRow">
                <div>
                  <button mat-raised-button (click)="startForFree(true)" color="primary">Start for Free</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="sectionContent-mobile column-flex">
            <span style="color: #09215c; font-size: 2.5rem; font-weight: 400;">
              A survey solution for for everyone!
              </span>
              <ul>
                <li>Tired of paying monthly for survey software that you don't need every month?</li>
                <li>Need more than one user but the costs are just growing?</li>
                <li>Frustrated at having to upgrade users to the next level to get the functionality you need?</li>
                <li>Budget is getting tighter and you need to become more cost effective?</li>
              </ul>
              <p>
                DIY Surveys is license free for any number of users, all functionlity included, just pay for what you use when you use if!
              </p>
              <p>
                <span class="strong">Get started with a Free Account</span>
              </p>
              <div [formGroup]="formGroup" class="fieldRow">
                <div>
                  <button mat-raised-button (click)="startForFree(true)" color="primary">Start for Free</button>
                </div>
              </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="sectionContent-mobile column-flex">
            <div class="left" [@leftIn]="start">
              <img src="../../assets/img/AllChannels.png" alt="We are connected to all channels (Email, SMS, WhatsApp, etc.)" class="fullWidth picture" />
            </div>
            <div class="right" [@rightIn]="start">
              <span style="color: #09215c; font-size: 2.5rem; font-weight: 400;">
                We are Connected!
                </span>
                <p>
                  DIY Surveys offer the only service that connects into: Email, SMS, Chat Solutions – WhatsApp, Facebook Messaging
                </p>
                <p>
                  <span class="strong">Get started with a Free Account</span>
                </p>
                <div [formGroup]="formGroup" class="fieldRow">
                  <div>
                    <button mat-raised-button (click)="startForFree(true)" color="primary">Start for Free</button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <div class="sectionContent-mobile column-flex">
            <div class="left" style="width:80%" [@leftIn]="start">
              <img src="../../assets/img/Enterprise.png" alt="An Enterprise solution with enterprise features" class="fullWidth picture" />
            </div>
            <div class="right" [@rightIn]="start">
                <span style="color: #09215c; font-size: 2.5rem; font-weight: 400;">
                  An Enterprise Solution
                  </span>
                  <p>
                    Our cloud solution includes:
                  </p>
                  <ul>
                    <li>A leading-edge front end</li>
                    <li>Multiple channels for survey invitations and two-way messaging using Web, WhatsApp and SMS</li>
                    <li>A worldwide audience accessible in an instant</li>
                    <li>The ability to import and export data in different formats over multiple data transports (FTP, SFTP, FTPS, HTTP, etc.)</li>
                    <li>All based on 'OneScript' our research ready scripting language</li>
                    <li>Available anywhere in the world</li>
                  </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
        <div id="surveys-mobile" class="section-mobile">
          <div class="sectionContent-mobile">
            <h1 style="font-size: 2.5rem; font-weight: 500; text-align: center;">Create a survey in minutes</h1>
            <h2 style="font-size: 1rem; font-weight: 500; text-align: center;">It's as easy as 1-2-3</h2>
          </div>
        </div>
        <div class="section-mobile">
          <div class="sectionContent-mobile">
            <div class="sectionCard-mobile">
              <div style="display:flex; align-items: center;">
                <span class="number">1</span>&nbsp;
                <div>
                  <h2 style="font-size: 1.5rem !important; font-weight: 400;">Use one of our survey templates or build your own</h2>
                </div>
              </div>
              <mat-card class="txt-align-center margin-10" (click)="scrollTo('step1')">
                <div style="margin:20px;margin-bottom:2px;">
                  <img src="../../assets/img/report1.svg" width="40" />
                </div>
                <span class="strong" style="margin-bottom:20px">Survey Templates</span>
                <mat-card-content style="margin-bottom:20px">
                  NPS, CES, CSAT Combination, Event Feedback, Coffee shop, Garage Service Survey…
                </mat-card-content>
              </mat-card>
              <div style="display:flex; align-items: center;">
                <span class="number">2</span>&nbsp;
                <div>
                  <h2 style="font-size: 1.5rem !important; font-weight: 400;">your own respodents or buy respondents to meet your needs</h2>
                </div>
              </div>
              <mat-card style="text-align: center; margin: 10px;">
                <div style="margin:20px;margin-bottom:2px;">
                  <img src="../../assets/img/report2.svg" width="40" />
                </div>
                <span class="strong mb-20">
                  Buy or Upload
                </span>
                <mat-card-content class="mb-20">
                  Upload your respondents or use our - <span [countUp]="[335,50000]"></span>+ million people, 130+ countries, 50+ demographics
                </mat-card-content>
              </mat-card>
              <div style="display:flex; align-items: center;">
                <span class="number">3</span>&nbsp;
                <div>
                  <h2 style="font-size: 1.5rem !important; font-weight: 400;">Choose the best channel for your audience</h2>
                </div>
              </div>
              <mat-card style="text-align:center; margin: 10px;">
                <div style="margin:20px;margin-bottom:2px;">
                  <img src="../../assets/img/report3.svg" width="40" />
                </div>
                <span class="strong mb-20">
                  Survey Templates
                </span>
                <mat-card-content class="mb-20">
                  Choose from Email, SMS, WhatsApp and many other channels to get your feedback
                </mat-card-content>
              </mat-card>
            </div>
            <div id="step1-mobile">
              <div style="display: flex; flex-flow: column; align-items: center">
                <div style="display:flex; align-items: center;">
                  <img src="../../assets/img/report4.svg" width="40" />
                  Results Analysis
                </div>
              </div>
            </div>
            <div style="text-align: center;">
              View the data, edit reports ready for presentation, or export your data to play with
            </div>
          </div>
        </div>
        <div style="background-color:white; margin-top:-350px; padding-top:350px">
          <div class="section-mobile">
            <div class="sectionContent-mobile" style="display: flex; flex-direction: column;">
              <div class="left" [@leftIn]="start && p1" (appear)="p1 = true" style="padding: 0;">
                <img src="../../assets/img/step1.svg" style="width:100%;" />
              </div>
              <div class="rightImage" style="width:100%" [@rightIn]="start && p1" (appear)="p1 = true">
                <div style="display: flex; align-items: center;">
                  <span class="number">1</span>&nbsp;
                  <h2>Use one of our survey templates</h2>
                </div>
                <h1>Rely on our Research Experience</h1>
                <p>
                  Access to survey templates to save you time and let you focus on your audience.
                </p>
                <mat-divider></mat-divider>
                <br />
                <p>
                  For an experienced researcher we offer a full question editor with simple look and feel controls and drag and drop controls.
                </p>
              </div>
            </div>
          </div>
          <div id="audience-mobile" class="section-mobile">
            <div class="sectionContent-mobile">
              <div class="left" [@leftIn]="start && p2">
                <img src="../../assets/img/landing-page-image.png" style="width: 100%;" />
              </div>
              <div class="rightImage" style="width:100%" [@rightIn]="start && p2" (appear)="p2 = true">
                <div style="display: flex; align-items: center;">
                  <span class="number">2</span>&nbsp;
                  <h2>
                    Upload your own respondents or buy respondents to meet your needs
                  </h2>
                </div>
                <h1>
                  Reach the right people
                </h1>
                <p>
                  335+ million people, 130+ countries, 50+ demographics.
                  Select target audiences quickly, easily and at a low price.
                  Choose the demographics that you require: age, gender, region, income and much much more (over 50+ attributes).
                </p>
                <mat-divider></mat-divider>
                <br />
                <p>
                  For those with their own panels you can simply upload through our easy to use UI,
                  or set up regular automated uploads through our comprehensive Data Import and Export facilities and our fully customizable API.
                </p>
              </div>
            </div>
          </div>
          <div id="step3-mobile" class="section-mobile">
            <div class="sectionContent-mobile">
              <div class="left" [@leftIn]="start && p3" style="padding: 0;">
                <img src="../../assets/img/AllChannels.png" style="width: 100%;" />
              </div>
              <div class="rightImage" style="width: 100%;" [@rightIn]="start && p3" (appear)="p3 = true">
                <div style="display: flex; align-items: center;">
                  <span class="number">3</span>&nbsp;
                  <h2>
                    Choose the best channel to invite your respondents to take your surveys
                  </h2>
                </div>
                <h1>
                  We are Connected!
                </h1>
                <p>
                  DIY Surveys is unique in that it provides Email, SMS and WhatsApp communication channels to deliver your surveys globally, you choose!
                </p>
                <mat-divider></mat-divider>
                <br />
                <p>
                  We offer the ability to plug into SMPP, custom API's and any service that provide the ability to send a message, either as a invitation service or a two-way research service.
                </p>
              </div>
            </div>
          </div>
          <div id="step4-mobile" class="section-mobile">
            <div class="sectionContent-mobile">
              <div class="left" [@leftIn]="start && p2">
                <img class="picture" alt="Understand your feedback" src="../../assets/img/Reporting.png" style="width:100%" />
              </div>
              <div class="rightImage" style="width:100%" [@rightIn]="start && p2" (appear)="p2 = true">
                <div style="display:flex;">
                  <h2>
                    Results Analysis
                  </h2>
                </div>
                <h1>
                  Integrated Reports
                </h1>
                <p>
                  View your survey data how you want. We provice fast and simple charts and tables in an Online results viewer and report
                  builder with auto Excel and Pdf exports, or you can export your data and do advanced analysis and reporting in your preferred statistics package.
                </p>
                <mat-divider></mat-divider>
                <br />
                <p>
                  For an experienced researcher we offer full integration with real time updates to schedules and batch updates in any format to any platform.
                </p>
              </div>
            </div>
          </div>
          <div id="pricing-mobile" class="section-mobile" style="background-color: #F6F6F6;margin:0;">
            <div style="margin:10px">
            <h1>
              Pricing
            </h1>
            <div class="sectionContent-mobile">
              <div class="left" [@leftIn]="start && p2" style="font-size: 0.75em">
                <h2>
                  Cash Customers
                </h2>
                <p>
                  DIY Users are what we call Cash customers and can use their Credit/Debit cards to top up their account balance.
                </p>
                <p>
                  When following the 3-step survey launch process, the platform estimates the costs based on the parameters you set, provides a cost and then if you are happy it meets your needs and your account credit is sufficient your survey can be launched.
                </p>
                <ul>
                  <li>No license fees.</li>
                  <li>Pay per survey complete £0.30p.</li>
                  <li>Pay per SMS or WhatsApp invitation - cost vary by country but global average is £0.05p.</li>
                  <li>Standard support and the use of knowledge base (How to) is included free.</li>
                </ul>
              </div>
              <div class="righte" [@rightIn]="start && p2" (appear)="p2 = true" style="font-size:0.75em">
                <h2>Credit Customers</h2>
                <p>
                  Larger customer with higher volume usage such as Market Researchers, Consultants, In-house Brand Insight teams, and CX / VoC platform providers are verified as Credit users.
                  This means that we will invoice your elected Accounts dept monthly for your usage unless, of course, you request that your users have cash accounts.
                </p>
                <ul>
                  <li>No license fees unless your usage requires premium support in which case annual license fees well be agreed on a case-by-case basis.</li>
                  <li>Pay per survey complete £0.30p basic but with volume breaks for scale of use.</li>
                  <li>Pay per SMS or WhatsApp invitation - costs vary by country but global average is £0.05p, again volume breaks are available dependent on scale of use.</li>
                  <li>Standard support and use of the knowledge base (How to) is included free.</li>
                </ul>
              </div>
              </div>
            </div>
          </div>
          <app-newfooter></app-newfooter>
        </div>
      </div>
