<h2>Policy Group Manager</h2>
<div class="alert alert-primary" role="alert">
  <div style="display:flex;flex-flow:row">
    <div>
      Welcome to the Policy Group Manager. Please use this with care as it controls the security of the platform.
    </div>
  </div>
</div>
<div>
  <div>
    <button mat-raised-button color="primary" (click)="createGroup()">New Policy Group</button>&nbsp;&nbsp;
  </div>
  <div>
    <div *ngFor="let group of groups; let i = index">
      <app-policy-group [group]="group" (onClick)="action($event)" [preview]="true"></app-policy-group>
    </div>
  </div>
</div>
