import { HttpHeaders } from "@angular/common/http";
export const environment = {
  production: true
};
export const baseUrl = "/api/";
export const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
export const cintUrl = "https://api.cintworks.net/";

export const version = "7.3 (20241213005)";

let i = "Unknown";
let url = window.location.href.toString();
if (url.startsWith("https://ire")) i = "Ireland";
else if (url.startsWith("https://ldn")) i = "London";
else if (url.startsWith("https://sg")) i = "Singapore";
else if (url.startsWith("https://staging")) i = "Staging";
else if (url.startsWith("https://ams")) i = "Amsterdam";
else if (url.startsWith("https://dev")) i = "Development";
else if (url.startsWith("https://lbg")) i = "Lloyds Banking Group (LBG)";
else if (url.startsWith("https://diysurveys")) i = "London";
export const instance = i;

export const SQUARE_APPLICATION_ID = "sandbox-sq0idb-jAeCFLLBCI2yNAtoLSP0aA";
export const SQUARE_ACCESS_TOKEN = "EAAAEEJl8yjySR1TMf-20DNWvw4g-LBQZXZMBe4OhdbNJGqDM_KB-DcvB39WuUHd";
export const SQUARE_LOCATION_ID = "LNR8K43REQ9HJ";

