<div class="alert alert-primary" role="alert">
  <div style="display:flex;flex-flow:row">
    <div>
      Welcome to the Notification Manager. Please treat this with care as it controls the user epxerience of the platform.
    </div>
  </div>
</div>
<div>
  <div>
    <button mat-raised-button color="primary" (click)="create()">New Notification</button>&nbsp;&nbsp;
  </div>
  <div>
    <div *ngFor="let notification of notifications; let i = index">
    </div>
  </div>
</div>
