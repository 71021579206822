import { Component } from '@angular/core';

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrl: './notification-management.component.css'
})
export class NotificationManagementComponent {
  notifications: [] = [];
  create() {
    // Create a new notification
  }
}
