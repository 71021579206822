import { FocusMonitor } from '@angular/cdk/a11y';
import { ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Output, Input } from '@angular/core';
import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tfentry',
  templateUrl: './tfentry.component.html',
  styleUrl: './tfentry.component.css'
})
export class TfentryComponent {

  @ViewChild('num1') num1: ElementRef<HTMLInputElement>;
  @ViewChild('num2') num2: ElementRef<HTMLInputElement>;
  @ViewChild('num3') num3: ElementRef<HTMLInputElement>;
  @ViewChild('num4') num4: ElementRef<HTMLInputElement>;
  @ViewChild('num5') num5: ElementRef<HTMLInputElement>;
  @ViewChild('num6') num6: ElementRef<HTMLInputElement>;

  formGroup: FormGroup;

  controls: any[];

  constructor(private _focusMonitor: FocusMonitor) {
    this.formGroup = new FormGroup({
      num1: new FormControl(),
      num2: new FormControl(),
      num3: new FormControl(),
      num4: new FormControl(),
      num5: new FormControl(),
      num6: new FormControl()
    })
  }

  setFocus(event: any, index: number, nextElement) {
    let key = event.key;
    let formControl = null;
    switch (index) {
      case 1:
        formControl = this.formGroup.controls.num1;
        break;
      case 2:
        formControl = this.formGroup.controls.num2;
        break;
      case 3:
        formControl = this.formGroup.controls.num3;
        break;
      case 4:
        formControl = this.formGroup.controls.num4;
        break;
      case 5:
        formControl = this.formGroup.controls.num5;
        break;
      case 6:
        formControl = this.formGroup.controls.num6;
        break;
    }

    switch (key) {
      case "ArrowRight":
        if (formControl.value?.length == 1) {
          this._focusMonitor.focusVia(nextElement, 'program');
          return;
        }

        return;
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
        if (index < 6) {
          formControl.setValue(key);
          this._focusMonitor.focusVia(nextElement, 'program');
          return;
        }

        if (index == 6) {
          let result = this.formGroup.controls.num1.value + this.formGroup.controls.num2.value + this.formGroup.controls.num3.value +
            this.formGroup.controls.num4.value + this.formGroup.controls.num5.value + this.formGroup.controls.num6.value;
          this.valueChange?.emit(result);
          this.completed?.emit(result);
          return;
        }

        break;
      case 'Backspace':
      case 'ArrowLeft':
        let currentElement = null;
        switch (index) {
          case 1:
            break;
          case 2:
            currentElement = this.num1;
            break;
          case 3:
            currentElement = this.num2;
            break;
          case 4:
            currentElement = this.num3;
            break;
          case 5:
            currentElement = this.num4;
            break;
          case 6:
            currentElement = this.num5;
            break;
        }

        if (formControl.value?.length == 1) {
          formControl.setValue('');
          return;
        }

        this._focusMonitor.focusVia(currentElement, 'program');
        return;
    }
  }

  setFocusToControl() {
    this._focusMonitor.focusVia(this.num1, 'program');
  }

  @Input()
  value: string;

  @Output()
  completed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();
}
